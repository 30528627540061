import { Link } from 'gatsby';
import React, { memo } from 'react';
import * as styles from './TopNavbar.module.css';
import Avatar from '../shared/Avatar';
import Logo from '../shared/Logo';

const TopNavbar = () => (
  <div className={styles.navbar}>
    <div className="container">
      <div className="flex items-center">
        <Link to="/">
          <Logo size="40px" />
        </Link>
        <h4 className="text-secondary-text ml-4 text-2xl">Resumoz resume</h4>
      </div>

      <Avatar className="ml-8" />
    </div>
  </div>
);

export default memo(TopNavbar);
